import { useRouter } from "next/router"
import Seo from "@common/seo"
import Loader from "@common/loader"
import { isDashboard } from "../lib/navigation.js"
import { useMounted } from "../lib/hooks.js"
import Logo from "@common/logo"
import LandingSimpleLayout from "../components/layouts/landingSimpleLayout"
import { THEME } from "../configs/theme.js"
import { useTheme } from "next-themes"
import { VerticalCenterContainer } from "@common/container"
import { useEffect } from "react"

export default function Custom404() {
  const isMounted = useMounted()
  const router = useRouter()
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"

  useEffect(() => {
    if (isDashboard(router.asPath)) {
      router.push("/login")
    }
  }, [router])

  if (isMounted == false) return <div></div>

  if (!router || !router.asPath)
    return (
      <div className="h-screen">
        <Loader />
      </div>
    )

  return (
    <LandingSimpleLayout>
      <Seo title={`Page Not Found - ${THEME?.seo?.siteName}`} />
      <VerticalCenterContainer minusValue={168}>
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Logo
              logoObj={THEME["logo"]}
              version="icon"
              className="h-12 mx-auto mb-8 w-auto"
              isDarkMode={isDarkMode}
            />
          </div>
          <div className="py-16">
            <div className="text-center space-y-4">
              <p className="text-sm font-semibold text-blue-theme uppercase tracking-wide">
                404 error
              </p>
              <div className="space-y-2">
                <h1 className=" text-4xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-5xl">
                  Page not found.
                </h1>
                <p className="text-base text-gray-600 dark:text-gray-300">
                  Sorry, we couldn’t find the page you’re looking for.
                </p>
              </div>
              <div className=" btn btn-blue-outline">
                <a href="/">
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </VerticalCenterContainer>
    </LandingSimpleLayout>
  )
}
