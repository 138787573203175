import Button from "@common/buttons/button"
import LinkButton from "@common/buttons/linkButton"
import { Header, HeaderProps } from "@common/header/headerUnit"
import { isNullOrEmpty } from "@common/lib/util"
import React, { ReactNode } from "react"

interface CtaUnitBaseProps extends HeaderProps {
  heading: string | ReactNode
  subHeading: string | ReactNode
  primaryActionBtnText?: string
  onPrimaryActionClick?: () => void
  secondaryActionBtnText?: string
  onSecondaryActionClick?: () => void
  link?: {
    text: string
    url: string
  }
}

export default function CtaUnit({
  heading,
  subHeading,
  primaryActionBtnText,
  onPrimaryActionClick,
  secondaryActionBtnText,
  onSecondaryActionClick,
  link,
  alignment = "left",
  variant = "h2",
}: CtaUnitBaseProps) {
  const contentAlignClass = `w-full ${
    alignment == "left"
      ? "justify-start items-start text-left"
      : alignment == "right"
        ? "justify-end items-start text-left"
        : "justify-center items-center text-right"
  } gap-4`

  const actionClass = `w-full flex ${
    alignment == "left"
      ? " justify-start items-start text-left"
      : alignment == "right"
        ? "  justify-end items-start text-left"
        : " justify-center items-center text-right"
  } gap-4`

  const hasActions = primaryActionBtnText || secondaryActionBtnText

  return (
    <div className=" h-full w-full  bg-muted rounded-2xl relative overflow-hidden">
      <div className="bg-red-100 dark:bg-red-900/20 w-52 h-52 rounded-full top-10 left-20 absolute animate-showPulse"></div>
      <div className="bg-blue-100 dark:bg-blue-900/20 w-52 h-52 rounded-full  top-40 right-20 absolute animate-showPulse"></div>
      <div className="bg-green-100 dark:bg-green-900/20 w-52 h-52 rounded-full  top-[50%] right-[50%] absolute animate-showPulse"></div>
      <div className=" backdrop-blur-3xl rounded-2xl justify-center flex items-center px-5 md:p10x- py-36 relative">
        <div className={contentAlignClass}>
          <Header alignment={alignment} variant={variant}>
            <Header.MainHeader>{heading}</Header.MainHeader>
            <Header.Description>{subHeading}</Header.Description>
          </Header>
          <div className="space-y-4 pt-10">
            {hasActions && (
              <div className={actionClass}>
                {secondaryActionBtnText && (
                  <Button
                    variant="minimal"
                    size="md"
                    onClick={onSecondaryActionClick}
                  >
                    {secondaryActionBtnText}
                  </Button>
                )}
                {primaryActionBtnText && (
                  <Button onClick={onPrimaryActionClick} size="md" variant="primary">
                    {primaryActionBtnText}
                  </Button>
                )}
              </div>
            )}
            {!isNullOrEmpty(link) && (
              <div className={actionClass}>
                <LinkButton
                  aria-label="link"
                  href={link?.url ?? ""}
                  target="_blank"
                  rel="noreferrer"
                  variant="link"
                  size="md"
                >
                  {link?.text}
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
