/* tri */

import Quota, { QuotaSmall } from "components/quota/quota"

export const triMain = {
  top: [
    {
      key: "",
      name: "TheReviewIndex",
      items: [],
      type: "logo",
    },
    {
      key: "home",
      name: "Home",
      icon: "home",
      items: [],
    },
    {
      key: "stats",
      name: "Reports",
      icon: "chart-square-bar",
      items: [],
    },

    {
      key: "search",
      name: "Search",
      icon: "search",
      items: [],
    },
    {
      key: "compare",
      name: "Compare",
      icon: "scale",
      items: [],
    },
  ],
  bottom: [
    {
      key: "manage",
      submenu_redirect_key: "manage/tasks", // because manage has a submenu
      name: "Manage",
      icon: "setting",
      items: [],
    },
    {
      key: "settings",
      submenu_redirect_key: "settings/preferences", // because manage has a submenu
      name: "Settings",
      icon: "cog",
      items: [],
    },
    {
      key: "help",
      name: "Help",
      icon: "help",
      newWindow: true,
      items: [],
    },
    {
      key: "logout",
      name: "Logout",
      icon: "logout",
      items: [],
    },
  ],
}

export const triManage = [
  {
    key: "manage",
    name: "Manage", // header
    items: [
      {
        key: "manage/tasks",
        name: "Data Sources",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/topics",
        name: "Topics",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/catalog",
        name: "Catalog",
        icon: undefined,
        items: [],
      },
    ],
  },
]

/* currents */

export const currentsMain = {
  top: [
    {
      key: "c/docs",
      name: "",
      items: [],
      type: "logo",
    },
    {
      key: "c/docs/new",
      name: "Quick Start",
      icon: "add-docs",
      newWindow: true,
      items: [],
    },
    {
      key: "c/docs",
      name: "Docs",
      icon: "folder",
      items: [],
    },
  ],

  bottom: [
    {
      key: "settings/plans",
      name: "Quota",
      icon: "quota",
      items: [],
      hoverComponent: () => <Quota />,
      expandComponent: () => <QuotaSmall />,
    },
    {
      key: "settings",
      submenu_redirect_key: "settings/preferences", // because manage has a submenu
      name: "Settings",
      icon: "cog",
      items: [],
    },
    {
      key: "help",
      name: "Help",
      icon: "help",
      newWindow: true,
      items: [],
    },
    {
      key: "logout",
      name: "Logout",
      icon: "logout",
      items: [],
    },
  ],
}

/* freetext */

export const freetextMain = {
  top: [
    {
      key: "",
      name: "FreeText",
      items: [],
      type: "logo",
    },
    {
      key: "home",
      name: "Home",
      icon: "home",
      items: [],
    },
    {
      key: "stats",
      name: "Reports",
      icon: "chart-square-bar",
      items: [],
    },
    {
      key: "search",
      name: "Search",
      icon: "search",
      items: [],
    },
    {
      key: "compare",
      name: "Compare",
      icon: "scale",
      items: [],
    },
  ],
  bottom: [
    {
      key: "manage",
      submenu_redirect_key: "manage/tasks", // because manage has a submenu
      name: "Manage",
      icon: "setting",
      items: [],
    },
    {
      key: "settings",
      submenu_redirect_key: "settings/preferences", // because manage has a submenu
      name: "Settings",
      icon: "cog",
      items: [],
    },
    {
      key: "help",
      name: "Help",
      icon: "help",
      newWindow: true,
      items: [],
    },
    {
      key: "logout",
      name: "Logout",
      icon: "logout",
      items: [],
    },
  ],
}

export const freetextManage = [
  {
    key: "manage",
    name: "Manage", // header
    items: [
      {
        key: "manage/tasks",
        name: "Data Sources",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/channels",
        name: "Channels",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/topics",
        name: "Topics",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/catalog",
        name: "Catalog",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/teams",
        name: "Teams",
        icon: undefined,
        items: [],
      },
      {
        key: "manage/users",
        name: "Users",
        icon: undefined,
        items: [],
      },
    ],
  },
]

/* common */

export const settings = [
  {
    key: "settings",
    name: "Settings",
    items: [
      {
        key: "settings/preferences",
        name: "Preferences",
        icon: undefined,
        items: [],
      },
      {
        key: "settings/plans",
        name: "Plans",
        icon: undefined,
        items: [],
      },
      {
        key: "settings/profile",
        name: "Profile",
        icon: undefined,
        items: [],
      },
      {
        key: "settings/release-notes",
        name: "Changelog",
        icon: undefined,
        items: [],
      },
    ],
  },
]
