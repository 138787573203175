// faq
import { freetextFaqs } from "configs/faqs/freetext"
import { currentsFaqs } from "configs/faqs/currents"
import { triFaqs } from "configs/faqs/tri"
// pricing
import { triPlans } from "configs/pricing/tri"
import { triPlanComparison } from "configs/pricing/tri"
import { ftPlans } from "configs/pricing/freetext"
import { ftPlanComparison } from "configs/pricing/freetext"
import { ftPricingFaqs } from "configs/pricing/freetext"

// footer
import { freetextFooterLinks } from "@common/configs/footer/freetext"
import { currentsFooterLinks } from "@common/configs/footer/currents"
import { triFooterLinks } from "@common/configs/footer/tri"
import { LoginTypeEnum } from "@common/types"
import { SettingsEnum } from "enums"

import {
  currentsMain,
  freetextMain,
  freetextManage,
  triMain,
  triManage,
  settings,
} from "./menu.js"
import LogoGridUnit from "@common/landing/logoGridUnit"
import { Header } from "@common/header/headerUnit"
import LinkButton from "@common/buttons/linkButton"
import Svg from "@common/svg"

export const SITE = process.env.NEXT_PUBLIC_SITE || "freetext"

// TODO
// 1. move all footer related variables to within "footer" key (e.g currents)
// 2. move all menu related variables to within "dashboardMenu" key
// 3. reorganize similarly when required.

const themes = {
  freetext: {
    homePath: "channels",
    //ga: "UA-115950847-1", (older universal analytics)
    ga: "G-DWVESMCXRP",
    favicon: "faviconFt.ico",
    logo: {
      white: "logo-white.svg",
      black: "logo-black.svg",
      "icon-white": "icon-logo-white.svg",
      "icon-black": "icon-logo-black.svg",
      "icon-blue": "icon-logo-blue.svg",
      svgName: "freetext",
    },
    site: "freetext",
    company: "FreeText AI Pte. Ltd.",
    pricing: {
      plans: ftPlans,
      compare: ftPlanComparison,
      faqs: ftPricingFaqs,
      hideTrial: true,
    },
    address: {
      line1: "68 Circular Road",
      line2: "#02-01 Singapore (049422)",
    },
    footerSummary:
      "Convert textual customer feedback from multiple sources - reviews, chats, tickets - into actionable insights and trends in minutes.",
    contactEmail: "support@freetext.ai",
    faqs: freetextFaqs,
    // homeComponent: <FreetextHome />,
    // aboutComponent: <FreetextAbout />,
    menus: {
      menuIcons: freetextMain,
      subMenuManage: freetextManage,
      subMenuSettings: settings,
    },
    footerLinks: freetextFooterLinks,
    headerLinks: [
      {
        name: "About",
        link: "/about",
        icon: "folder",
      },
      {
        name: "Pricing",
        link: "/pricing",
        icon: "pricing",
      },
      {
        name: "FAQs",
        link: "/faqs",
        icon: "question-circle",
      },
      {
        name: "Contact",
        link: "/contact",
        icon: "mail-open",
      },
    ],
    /**
     * NOTE:
     * rename this as headerLinks (delete old one)
     * when header.js uses new NavDesktopMenu component
     */
    headerLinksNew: [
      {
        display: "Integrations",
        links: [
          {
            display: "Zendesk",
            link: "/channels/zendesk",
            imgSrc: "/images/channelsIcon/zd_support.png",
          },
          {
            display: "Hubspot",
            link: "/channels/hubspot",
            imgSrc: "/images/channelsIcon/hubspot.png",
          },
          {
            display: "Typeform",
            link: "/channels/typeform",
            imgSrc: "/images/channelsIcon/typeform.png",
          },
        ],
        ctaLink: {
          display: "View All Integrations",
          link: "/channels",
        },
        illustration: (
          <div className="w-full h-full bg-muted rounded-md">
            {[
              {
                title: "Connect",
                description: "Authorize Freetext AI to read data.",
                icon: "paper-clip",
              },
              {
                title: "Let AI do it's magic",
                description:
                  "Our Custom fine-tuned LLMs will automatically tag tickets",
                icon: "clock",
              },
              {
                title: "Insights & Reports",
                description: "Gain insight into customer pain-points within minutes",
                icon: "pie",
              },
            ].map((step, index) => (
              <div key={index} className="grid grid-cols-4 items-center py-4">
                <div className="w-10 h-10 flex items-center justify-center rounded-full mx-auto bg-primary text-primary-foreground">
                  <Svg name={step.icon} classes="w-5 h-5" strokeWidth={2} />
                </div>
                <div className="col-span-3">
                  <div className="text-sm md:text-base text-primary">
                    {step.title}
                  </div>
                  <div className="text-xs md:text-sm text-secondary">
                    {step.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        display: "Company",
        links: [
          {
            display: "About",
            link: "/about",
            icon: "info",
          },
          {
            display: "Pricing",
            link: "/pricing",
            icon: "bank-notes",
          },
          {
            display: "FAQS",
            link: "/faqs",
            icon: "question-circle",
          },
          {
            display: "Contact Us",
            link: "/contact",
            icon: "envelope",
          },
          {
            display: "Terms of Use",
            link: "/terms",
            icon: "clipboard",
          },
        ],
        illustration: (
          <div className="w-full h-full flex justify-center items-center bg-muted rounded-md p-2">
            <div className="space-y-4">
              <div>
                <Header.Description>Ready to dive in?</Header.Description>
                <Header.MainHeader>Schedule a demo now.</Header.MainHeader>
              </div>
              <div className="justify-start items-center">
                <LinkButton variant="primary" href="/contact">
                  Get Started
                </LinkButton>
                <LinkButton variant="minimal" href="/faqs">
                  Learn more
                </LinkButton>
              </div>
            </div>
          </div>
        ),
      },
      {
        display: "Security",
        links: [
          {
            display: "Privacy",
            link: "/privacy",
            icon: "lock-closed",
          },
          {
            display: "View All",
            link: "/trust",
            icon: "shield",
          },
        ],
        illustration: (
          <div className=" px-2 py-8 bg-muted rounded-md flex justify-center items-center">
            <LogoGridUnit
              gridIcons={[
                { imgPath: "/images/compliance/soc_icon.png" },
                { imgPath: "/images/compliance/gdpr.png" },
                { imgPath: "/images/compliance/iso_icon.png" },
              ]}
              logoSize={80}
            />
          </div>
        ),
      },
      {
        display: "Pricing",
        links: "/pricing",
      },
    ],
    headerCtaLinks: [
      {
        name: "Sign in",
        link: "/login",
      },
      {
        name: "Sign Up",
        link: "/signup",
      },
    ],
    seo: {
      url: "https://freetext.ai",
      logoUrl: "https://freetext.ai/images/ft-logo-112x112-black.png",
      title: "FreeText AI - Customer Feedback Analysis powered by AI",
      description:
        "Convert textual customer feedback from multiple sources - reviews, chats, tickets - into actionable insights and trends in minutes.",
      homeImageUrl: "https://freetext.ai/images/ft-og.png",
      siteName: "FreeText AI",
      locale: "en_US",
    },
    helpLink: "https://freetext.ai/docs",
    blackList: [
      "/onboarding",
      "/enterprise",
      // "/c/.*"
    ], // ---> KEEP THE CONTENTS IN LOWERCASE
    homePageConfig: {
      showSources: true,
      showBookmarks: true,
      showReportsSection: false,
    },
    iconSidenavExpandable: false,
    loginType: [LoginTypeEnum.Login],
    settingsConfig: [
      SettingsEnum.general,
      SettingsEnum.email,
      SettingsEnum.appearance,
    ],
  },

  tri: {
    homePath: "onboarding",
    ga: "UA-78263144-1",
    //favicon: "favicontri.ico",
    favicon: "tri-favicon-new.ico",
    logo: {
      white: "tri-white-new.svg",
      black: "tri-black-new.svg",
      "icon-white": "tri-icon-white-new.svg",
      "icon-black": "tri-icon-black-new.svg",
      "icon-blue": "tri-icon-blue-new.svg",

      /*
      white: "tri-white.svg",
      black: "tri-black.svg",
      icon-white: "tri-icon-white.svg",
      icon-black: "tri-icon-black.svg",
      icon-blue: "tri-logo-blue.svg",
      */
      svgName: "tri",
    },
    site: "tri",
    company: "TheReviewIndex",
    address: {
      line1: "68 Circular Road",
      line2: "#02-01 Singapore (049422)",
    },
    footerSummary:
      "Convert reviews, ratings and other eCommerce data into actionable insights within minutes.",
    contactEmail: "root@thereviewindex.com",
    pricing: { plans: triPlans, compare: triPlanComparison },
    faqs: triFaqs,
    // homeComponent: <CurrentsHome />,
    // aboutComponent: <CurrentsAbout />,
    footerLinks: triFooterLinks,
    menus: {
      menuIcons: triMain,
      subMenuManage: triManage,
      subMenuSettings: settings,
    },
    headerLinks: [
      {
        name: (
          <div className="flex gap-1">
            <img src="/images/icons/chromeIcon.svg" alt="chrome plugin" />
            Get Chrome Plugin
          </div>
        ),
        link: "https://chrome.google.com/webstore/detail/thereviewindex-reviews-su/fciomokgnajoifiiaglcjkonligobneo",
        icon: "puzzle",
      },
      {
        name: (
          <div className="flex gap-1">
            <img src="/images/icons/firefoxIcon.svg" alt="firefox plugin" />
            Get Firefox Plugin
          </div>
        ),
        link: "https://addons.mozilla.org/en-US/firefox/addon/thereviewindex/",
        icon: "puzzle",
      },
    ],
    /**
     * NOTE:
     * rename this as headerCtaLinks (delete old one)
     * when header.js uses new NavDesktopMenu component
     */
    headerLinksNew: [
      {
        display: (
          <div className="flex gap-1">
            <img src="/images/icons/chromeIcon.svg" alt="chrome plugin" />
            Get Chrome Plugin
          </div>
        ),
        links:
          "https://chrome.google.com/webstore/detail/thereviewindex-reviews-su/fciomokgnajoifiiaglcjkonligobneo",
        icon: "puzzle",
      },
      {
        display: (
          <div className="flex gap-1">
            <img src="/images/icons/firefoxIcon.svg" alt="firefox plugin" />
            Get Firefox Plugin
          </div>
        ),
        links: "https://addons.mozilla.org/en-US/firefox/addon/thereviewindex/",
        icon: "puzzle",
      },
    ],
    mobileLinks: [
      {
        name: "About",
        link: "/about",
        icon: "folder",
      },
      {
        name: "Pricing",
        link: "/pricing",
        icon: "pricing",
      },
      {
        name: "Terms",
        link: "/terms",
        icon: "terms",
      },
      {
        name: "Privacy",
        link: "/privacy",
        icon: "shield",
      },
    ],
    email: "root@TheReviewIndex.com",
    headerCtaLinks: [
      {
        name: "Sign in",
        link: "/login",
      },
      {
        name: "Try Pro For Free!",
        link: "/signup",
      },
    ],
    seo: {
      url: "https://thereviewindex.com/",
      logoUrl: "https://thereviewindex.com/images/tri-112-black.png",
      title: "thereviewindex - Review Analysis for Amazon Reviews",
      description:
        "Summary of thousands of user reviews, organized feature wise, along with pros/cons, for comparison and review analysis.",
      homeImageUrl: "https://thereviewindex.com/images/thereviewindex-og.png",
      siteName: "thereviewindex",
      locale: "en_US",
    },
    helpLink: "https://freetext.ai/docs",
    blackList: ["/teams", "/users", "/channels"],
    homePageConfig: {
      showSources: true,
      showBookmarks: true,
      showReportsSection: true,
    },
    loginType: [LoginTypeEnum.Login],
    settingsConfig: [
      SettingsEnum.general,
      SettingsEnum.email,
      SettingsEnum.appearance,
    ],
  },
}

export const THEME = themes[SITE]
