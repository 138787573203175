/**
 * @type {Any}
 */
export const channelsConfig = {
  REDDIT: {
    display: "Reddit",
    imageUrl: "/images/channelsIcon/reddit.png",
    description: "Social",
    sectionKey: "available",
    type: "connector",
    formFields: {
      name: { element: "input", label: { label: "Task Name" }, type: "text" },
      query: { element: "input", label: { label: "Search Reddit" }, type: "text" },
      search_type: {
        element: "listbox",
        label: { label: "Type" },
        options: {
          posts: {
            key: "posts",
            display: "Posts",
          },
          subreddits: {
            key: "subreddits",
            display: "Communities",
          },
        },
        value: "posts",
      },
      subreddits: {
        element: "multi_input",
        label: {
          label: "Filter Subreddits",
        },
        value: null,
        invisible: true,
      },
      time_filter: {
        element: "listbox",
        label: {
          label: "Time Filter",
        },
        options: {
          all: {
            key: "all",
            display: "ALL",
          },
          year: {
            key: "year",
            display: "YEAR",
          },
          month: {
            key: "month",
            display: "MONTH",
          },
          week: {
            key: "week",
            display: "WEEK",
          },
          day: {
            key: "day",
            display: "DAY",
          },
          hour: {
            key: "hour",
            display: "HOUR",
          },
        },
        value: "all",
        validation: {
          required: true,
        },
      },
      sort: {
        element: "listbox",
        label: {
          label: "Sort",
        },
        options: {
          top: {
            key: "top",
            display: "TOP",
          },
          hot: {
            key: "hot",
            display: "HOT",
          },
          new: {
            key: "new",
            display: "NEW",
          },
          rising: {
            key: "rising",
            display: "RISING",
          },
        },
        value: "hot",
        validation: {
          required: true,
        },
        invisible: false,
      },
      should_crawl_comments: {
        element: "checkbox",
        label: {
          label: "Should crawl comments?",
          position: "right",
        },
        value: false,
        validation: {
          required: true,
        },
      },
      periodic_crawl_enabled: {
        element: "checkbox",
        label: {
          label: "Crawl data Periodically",
          position: "right",
        },
      },

      periodic_crawl_limit: {
        element: "input",
        type: "number",
        label: {
          label: "Periodic Results Limit",
        },
        invisible: true,
        validation: {
          required: true,
        },
      },

      one_time_crawl_limit: {
        element: "input",
        label: {
          label: "Historic Results Limit",
        },
        type: "number",
        required: true,
      },
      channel: {
        element: "channel",
        label: "Channel",
        type: "string",
        value: "REDDIT",
        invisible: true,
      },
    },
    behaviors: [
      {
        name: "Show subreddits for Communities",
        conditions: [
          {
            type: "rule",
            field: "search_type",
            operator: "equals",
            value: "subreddits",
          },
        ],
        actions: [{ type: "show", targetField: "subreddits" }],
      },
      {
        name: "Show periodic_crawl_limit for periodic_crawl_enabled",
        conditions: [
          {
            type: "rule",
            field: "periodic_crawl_enabled",
            operator: "equals",
            value: true,
          },
        ],
        actions: [{ type: "show", targetField: "periodic_crawl_limit" }],
      },
    ],

    apiUrl: "data_source/user_task",
    requestSchema: {
      user_task: {
        name: "",
        periodic_crawl_enabled: "",
        task_meta: [
          {
            query: null,
            search_type: null,
            subreddits: null,
            time_filter: null,
            sort: null,
            crawl_limit: null,
            channel: null,
            one_time_crawl_limit: null,
            periodic_crawl_limit: null,
            should_crawl_comments: null,
          },
        ],
      },
      annotated_data_sources: [],
    },
  },
  HUBSPOT: {
    display: "HubSpot",
    description: "CRM",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imageUrl: "/images/channelsIcon/hubspot.png",
    apiUrl: "data_source/connectors/HUBSPOT/authorize",
    landingPageUrl: "/hubspot",
  },

  INTERCOM: {
    display: "Intercom",
    description: "Conversations",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imageUrl: "/images/channelsIcon/intercom.png",
    apiUrl: "data_source/connectors/INTERCOM/authorize",
    // landingPageUrl: "/intercom",
  },

  SALESFORCE: {
    display: "Salesforce",
    description: "Cases, LiveChatTranscript",
    type: "connector",
    sectionKey: "available",
    formFields: {
      domain: {
        element: "input",
        value: null,
        label: {
          label: "Org Domain",
        },
        validation: {
          required: true,
        },
      },
    },
    imageUrl: "/images/channelsIcon/salesforce.png",
    redirectUri: "connect",
    apiUrl: "data_source/connectors/SALESFORCE/authorize",
    // landingPageUrl: "/salesforce",
  },

  SURVEYMONKEY: {
    display: "SurveyMonkey",
    description: "Surveys",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imageUrl: "/images/channelsIcon/surveymonkey.png",
    formFields: {
      survey_titles: {
        element: "multi_input",
        label: {
          label: "Survey Titles",
        },
        value: null,
      },
    },
    apiUrl: "data_source/connectors/SURVEYMONKEY/authorize",
    // landingPageUrl: "/surveymonkey",
  },

  TYPEFORM: {
    display: "Typeform",
    description: "Forms and Surveys",
    type: "connector",
    sectionKey: "available",
    redirectUri: "connect",
    imageUrl: "/images/channelsIcon/typeform.png",
    formFields: {
      form_titles: {
        element: "multi_input",
        label: {
          label: "Form Titles",
        },
        value: null,
      },
    },
    apiUrl: "data_source/connectors/TYPEFORM/authorize",
    landingPageUrl: "/typeform",
  },

  "ZD-SUPPORT": {
    display: "Zendesk Support",
    description: "Support Tickets",
    type: "connector",
    sectionKey: "available",
    formFields: {
      subdomain: {
        element: "input",
        label: {
          label: "Sub Domain",
        },
        value: null,
        validation: {
          required: true,
        },
      },
      write_back_tags: {
        element: "checkbox",
        label: {
          label: "Allow FreeText AI to write tags into Zendesk.",
          labelPosition: "right",
        },
        value: false,
      },
    },
    redirectUri: "connect",
    imageUrl: "/images/channelsIcon/zd_support.png",
    apiUrl: "data_source/connectors/ZD-SUPPORT/authorize",
    landingPageUrl: "/zendesk",
  },

  UPLOADCSV: {
    display: "Upload CSV",
    sectionKey: "available",
    description: "CSV File",
    type: "upload_csv",
    imageUrl: "/images/channelsIcon/csv.png",
  },

  "AZ-US": {
    display: "Amazon US",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-UK": {
    display: "Amazon UK",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-IN": {
    display: "Amazon India",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "KKC-JP": {
    display: "Kakaku",
    imageUrl: "/images/channelsIcon/kakaku.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  COSTCO: {
    display: "CostCo",
    imageUrl: "/images/channelsIcon/costco.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  ACE: {
    display: "CostCo",
    imageUrl: "/images/channelsIcon/ace.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "AZ-FR": {
    display: "Amazon France",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-CA": {
    display: "Amazon Canada",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-ES": {
    display: "Amazon Spain",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "AZ-DE": {
    display: "Amazon Germany",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "FK-IN": {
    display: "Flipkart",
    imageUrl: "/images/channelsIcon/flipkart.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BB-US": {
    display: "BestBuy US",
    imageUrl: "/images/channelsIcon/bestbuy.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BB-CA": {
    display: "BestBuy Canada",
    imageUrl: "/images/channelsIcon/bestbuy.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  HOMEDEPOT: {
    display: "HomeDepot",
    imageUrl: "/images/channelsIcon/hd.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "HOMEDEPOT-CA": {
    display: "Home Depot Canada",
    imageUrl: "/images/channelsIcon/hd.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "TGT-US": {
    display: "Target",
    imageUrl: "/images/channelsIcon/target.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "CPNG-KR": {
    display: "Coupang",
    imageUrl: "/images/channelsIcon/coupang.svg",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },

  "NEWEGG-US": {
    display: "NewEgg US",
    imageUrl: "/images/channelsIcon/newegg.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  PLSTR: {
    display: "Play Store",
    imageUrl: "/images/channelsIcon/play_store.png",
    description: "Apps",
    sectionKey: "available",
  },
  APPSTORE: {
    display: "App Store",
    imageUrl: "/images/channelsIcon/app_store.png",
    description: "Apps",
    sectionKey: "available",
  },

  twitter: {
    display: "Twitter",
    imageUrl: "/images/channelsIcon/twitter.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  youtube: {
    display: "Youtube",
    imageUrl: "/images/channelsIcon/youtube.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  instagram: {
    display: "Instagram",
    imageUrl: "/images/channelsIcon/instagram.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  facebook: {
    display: "Facebook",
    imageUrl: "/images/channelsIcon/facebook.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  tiktok: {
    display: "TikTok",
    imageUrl: "/images/channelsIcon/tictok.png",
    description: "Social",
    sectionKey: "coming_soon",
  },
  "AZ-JP": {
    display: "Amazon Japan",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "coming_soon",
    type: "ecommerce",
  },
  "AZ-IT": {
    display: "Amazon Italy",
    imageUrl: "/images/channelsIcon/amazon.png",
    description: "eCommerce",
    sectionKey: "coming_soon",
    type: "ecommerce",
  },
  SEPHORA: {
    display: "Sephora",
    imageUrl: "/images/channelsIcon/sephora.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  ULTA: {
    display: "Ulta",
    imageUrl: "/images/channelsIcon/ulta.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "MMKTS-DE": {
    display: "MediaMarkt (Germany)",
    imageUrl: "/images/channelsIcon/mediamarkt.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "WMT-US": {
    display: "Walmart US",
    imageUrl: "/images/channelsIcon/walmart.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "CURRYS-UK": {
    display: "Curry's",
    imageUrl: "/images/channelsIcon/currys.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "BLG-FR": {
    display: "Boulanger",
    imageUrl: "/images/channelsIcon/boulanger.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "FNAC-FR": {
    display: "FNAC",
    imageUrl: "/images/channelsIcon/fnac.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
  "JBH-AU": {
    display: "JB Hi-Fi",
    imageUrl: "/images/channelsIcon/jbhifi.png",
    description: "eCommerce",
    sectionKey: "available",
    type: "ecommerce",
  },
}

export const sections = {
  active: {
    header: "Active",
    description: "These channels are enabled for your account.",
    cardType: "flatCard",
  },

  available: {
    header: "Available",
    description: "Other channels that are available. Get in touch to enable these.",
    cardType: "flatCard",
  },

  coming_soon: {
    header: "Coming Soon",
    description: "Channels and connectors that are in development.",
    cardType: "flatCard",
  },
}
